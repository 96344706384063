import React from 'react';
import { Collapse, ListGroup, Row, Col, ListGroupItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser} from '@fortawesome/free-regular-svg-icons';
import { faBell} from '@fortawesome/free-regular-svg-icons';

function onToggle(event, userId, selectedRow, onSelectRow) {
    event.stopPropagation();
    onSelectRow(selectedRow ? null : userId);
}

function handleMissingvalue(value, placeholder = "") {
    if(typeof value === "number" && !isNaN(value)) {
        return String(value);
    } else if(value && value.length) {
        return value;
    }

    return placeholder;
}

// const covidTestResultMap = {
//     "0": "Negative",
//     "1": "positive"
// }

const cssSelectedBreachTrack = {
    backgroundColor: "rgba(81, 188, 218, 0.2)"
}

function Track({
    breachData,
    seq,
    selected = false
 }) {
    // const borderColor = breach ? "darkgreen" : "indianred";
    return <a href="javascript:void(0)" style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem", borderBottom: "1px solid #ccc", fontWeight: 500, color: "rgb(102, 102, 102)", fontSize: 13, display: "flex", alignItems: "center", backgroundColor: selected? cssSelectedBreachTrack.backgroundColor: "transparent" }}>
        <span className="" style={{ alignItems: "center", display: "flex", paddingLeft: ".75rem", paddingRight: ".75rem" }}><span className="badge badge-dark badge-pill">{seq}</span></span>
        <span className="">
            <i className="fa fa-clock-o" style={{
                fontSize: 14
            }}></i>&nbsp;
            {new Date(breachData[breachData.length - 1].breachReportingTime).toLocaleDateString()}&nbsp;
            {new Date(breachData[breachData.length - 1].breachReportingTime).toLocaleTimeString()}
        </span>
    </a>
}

const markerLabels = "a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z".split(",");

const cssSectionHeader = { marginTop: ".5rem", padding: 0, display: "flex", alignItems: "center", fontWeight: "bold" };

const cssUserDetailKeyValue = {backgroundColor: "#F3F2F7", marginBottom: 4, padding: ".5rem 0px", display: "flex"};

const cssMapMaxHeight = 260;

export function TrackerRow({ userId, name, phone, email,
    gender, region, ageGroup,
    quarantineStartTime, quarantineEndDate,
    quarantinePlannedStartDate, quarantinePlannedEndDate, quarantineAddress,
    riskValue, breachData = [], onSelectBreach,
    onSelectRow, selectedRow, breachs,
    mapReactElement = null, onSetMapRoute }) {

    const [selectedBreachSeq, setSelectedBreachSeq] = React.useState(null);

    return <>
        <tr className={selectedRow ? "selected-row" : ""}>
            <td className="border-top-0">
                <i onClick={e => onToggle(e, userId, selectedRow, onSelectRow)} style={{ fontSize: 23 }} className={`fa ${selectedRow ? 'fa-angle-up' : 'fa-angle-down'}`} />
            </td>
            <td className="border-top-0" >{name || ""} </td>
            <td className="border-top-0" >{phone}</td>
            <td className="border-top-0" >{email}</td>
            <td className="border-top-0" >{quarantineStartTime}</td>
            <td className="border-top-0" >{quarantineEndDate}</td>
        </tr>
        <tr className="border-bottom">
            <td className=" border-top-0 p-0" colSpan="6">
                <Collapse isOpen={selectedRow} toggler="#toggler">
                    <Row style={{ borderBottom: "1px solid #EEE" }}>
                        <Col className="col-md-4 col-sm-12">
                            <h5 style={cssSectionHeader}>
                            <FontAwesomeIcon icon={faUser} style={{
                                fontSize: '24px',
                                marginRight: '5px'
                            }} />
                                {/* <i className="far fa-user" style={{
                                    fontSize: '28px'
                                }}></i>{'\u00A0'} */}
                                <span>User Details</span>
                            </h5>
                            <Row style={{ fontSize: "small", paddingLeft: ".75rem", height: '214px', overflow: 'auto' }}>
                                <Col className="col-sm-12" style={cssUserDetailKeyValue}>
                                    <div className="col-sm-4 field-label" style={{ fontWeight: "bold" }}>Age group</div>
                                    <div className="col-sm-6 field-value">: {handleMissingvalue(ageGroup, "Not Available")}</div>
                                </Col>
                                <Col className="col-sm-12" style={cssUserDetailKeyValue}>
                                    <div className="col-sm-4 field-label" style={{ fontWeight: "bold" }}>Gender</div>
                                    <div className="col-sm-6 field-value">: {handleMissingvalue(gender, "Not Available")}</div>
                                </Col>
                                {/* <Col className="col-sm-12" style={cssUserDetailKeyValue}>
                                    <div className="field-label" style={{ fontWeight: "bold" }}>Covid Test Details</div>
                                    <div className="field-value">{handleMissingvalue(covidTestResultMap[isQuarantine], "Not Available")}</div>
                                </Col> */}
                                {/* <Col className="col-sm-12" style={{}}>
                                    <div className="field-label" style={{ fontWeight: "bold" }}>Quarantine registered on</div>
                                    <div className="field-value">Jun 15, 2020</div>
                                </Col>
                                <Col className="col-sm-12" style={cssUserDetailKeyValue}>
                                    <div className="field-label" style={{ fontWeight: "bold" }}>Quarantine started on</div>
                                    <div className="field-value">Jun 16, 2020</div>
                                </Col> */}
                                {/* <Col className="col-sm-12" style={cssUserDetailKeyValue}>
                                    <div className="field-label" style={{ fontWeight: "bold" }}>Planned Quarantine Start Date</div>
                                    <div className="field-value">{handleMissingvalue(quarantinePlannedStartDate, "Not Available")}</div>
                                </Col>
                                <Col className="col-sm-12" style={cssUserDetailKeyValue}>
                                    <div className="field-label" style={{ fontWeight: "bold" }}>Planned Quarantine End Date</div>
                                    <div className="field-value">{handleMissingvalue(quarantinePlannedEndDate, "Not Available")}</div>
                                </Col> */}
                                <Col className="col-sm-12" style={cssUserDetailKeyValue}>
                                    <div className="col-sm-4 field-label" style={{ fontWeight: "bold" }}>Risk Level</div>
                                    <div className="col-sm-6 field-value">: {handleMissingvalue(riskValue, "Not Available")}</div>
                                </Col>
                                <Col className="col-sm-12" style={cssUserDetailKeyValue}>
                                    <div className="col-sm-4 field-label" style={{ fontWeight: "bold" }}>Region</div>
                                    <div className="col-sm-6 field-value">: {handleMissingvalue(region, "Not Available")}</div>
                                </Col>
                                <Col className="col-sm-12" style={cssUserDetailKeyValue}>
                                    <div className="col-sm-4 field-label" style={{ fontWeight: "bold" }}>Quarantine Address</div>
                                    <div className="col-sm-6 field-value" style={{display: "flex"}}><div>:{'\u00A0'}</div><div>{handleMissingvalue(quarantineAddress, "Not Available")}</div></div>
                                </Col>
                            </Row>
                        </Col>

                        <Col className="col-md-3 col-sm-12">
                            <h5 style={cssSectionHeader}>
                            <FontAwesomeIcon icon={faBell} style={{
                                fontSize: '24px',
                                marginRight: '5px'
                            }}/>
                                <span>Breach Details</span>
                            </h5>
                            {breachs && breachs.length ?
                            <div style={{height: cssMapMaxHeight, overflowY: "auto"}}><ListGroup>
                                {breachs.map((track, i) =>
                                    <ListGroupItem onClick={() => {
                                        onSelectBreach(track);
                                        setSelectedBreachSeq(track.seq); }} key={i} style={{ borderRadius: 0, padding: 0, margin: 0, backgroundColor: i % 2 === 0 ? "transparent" : cssUserDetailKeyValue.backgroundColor }}><Track {...track} selected={selectedBreachSeq === track.seq} marker={markerLabels[i]} /></ListGroupItem>)}
                            </ListGroup></div> : <p style={{ color: "#666", height: 210, display: "flex", alignItems: "center", justifyContent: "center", padding: ".75rem", backgroundColor: "#F3F2F7", /* marginTop: ".75rem", */ textAlign: "center" }}>User has not breached self quarantine guidelines.</p>}

                        </Col>
                        <Col className="col-md-5 col-sm-12" style={{ display: "flex", flexFlow: "column", height: cssMapMaxHeight, paddingLeft: 0 }}>
                            <h5 style={cssSectionHeader}>
                            <i className="fa fa-map-marker" style={{
                                fontSize: '24px',
                                marginRight: '5px'
                            }}/>
                                <span>Location</span>
                            </h5>
                            <div className="map-container" style={{ flexGrow: 1, height: cssMapMaxHeight }}>
                                {mapReactElement}
                            </div>
                        </Col>
                    </Row>
                </Collapse>
            </td>
        </tr>
    </>
}
