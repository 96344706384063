/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useEffect } from "react";

// reactstrap components
import { Row, Container, Col } from "reactstrap";
import constants from '../../constants';

const cssContactRow = {
  lineHeight: 24 + "px"
}

function DemoFooter({ userDetails }) {
  return (
    <footer className="footer  sticky-bottom footer-black footer-white">
      <Container>
       {userDetails && <Row>
          <Col md='12'>
            <div className="contact">
              Contact Info
            </div>
          </Col> 
        </Row>}
        {userDetails ? 
        <Row>
         <Col md='6'>
            <Row>
              <Col md='12'>
                  <h5 style={{marginBottom: 0}}>{userDetails.name}</h5>
                  </Col>
              <Col md='12' style={cssContactRow}>
                <i className="fa fa-phone" />
                {constants.jsxHtmlAltCode.nbsp}
                {userDetails.phone}
                  </Col>
              <Col md='12' style={cssContactRow}>
                <i className="fa fa-envelope" />
                {constants.jsxHtmlAltCode.nbsp}
                {userDetails.email}
                  </Col>
            </Row>
          </Col>
          <Col md='6' className="text-xs-left text-md-right">
            <span className="copyright">
              {/* © iQuent Technologies LLC © 2020 | PRIVACY */}
              Powered by Ministry of Communications
            </span>
          </Col> 
        </Row> : <Row className="justify-content-end"> 
        <span className="copyright">
            Powered by Ministry of Communications
            </span>
          </Row>}
      </Container>
    </footer>
  );
}

export default DemoFooter;
