//after login
import CommonNavbar from 'components/Navbars/CommonNavbar';
import React, { useState, useEffect } from 'react';
import utils from 'utils';
import DemoFooter from 'components/Footers/DemoFooter';
import { get } from 'apis/api';

const DashBoardPage = ({ children }) => {
    const [userDetails, setUserDetails] = useState(null)
    useEffect(() => {
        const getContactDetails = async () => {
            await get('/user/contact-us', successCb, errorCb)
        };
        getContactDetails();
    }, [])

    useEffect(() => {
        async function fetchUserData() {
            await get('/user/detail', successCbUserDetails, () => { });
        }
        fetchUserData();
    }, []);

    const successCbUserDetails = async (userDetails) => {
        if (userDetails && userDetails.mobileNumber) {
            utils.setUserInfo({ mobileNumber: userDetails.mobileNumber })
        }
    }

    const successCb = (data) => {
        if (data.contactUs)
            setUserDetails(data.contactUs[0].ContactUs)
    }

    const errorCb = () => {

    }
    return (<>
        <CommonNavbar shouldShowWhiteHeader={true} navItems={utils.getNavData()} />
        <div className="header-margin authorized-pages bg-light-color">
            {children}
        </div>
        <DemoFooter userDetails={userDetails} />


    </>)
}

export default DashBoardPage
