import React from 'react';
import { Card, CardBody} from 'reactstrap';

const cssSelectedCountWidget = {
    transform: "scale(1.125)",
    backgroundColor: "rgba(81, 188, 218, 0.2)"
}

export function CountWidget({ count, color, title, imageSrc, selected, onClick }) {
    // return <Card>
    //     <CardHeader>
    //         <div style={{ color, textAlign: "center", fontWeight: "bold" }}>
    //             {title}
    //         </div>
    //     </CardHeader>
    //     <CardBody style={{ padding: 10 }}>
    //         <div style={{ textAlign: "center", fontSize: "xx-large", color }}>
    //             {count}
    //         </div>
    //     </CardBody>
    // </Card>;

    const cssCard = { padding: '.5rem 0', backgroundColor: "#F3F2F7", cursor: 'pointer' };

    if(selected === title) {
        Object.assign(cssCard, cssSelectedCountWidget);
    }

    return <Card style={cssCard} onClick={e => onClick(title)}>
        <CardBody  style={{padding: '0 1.25rem', alignSelf: 'center'}}>
            <div style={{ display: "flex", alignItems: "center", color: color, justifyContent: 'center' }}>
                <img src={imageSrc} style={{ marginRight: '10px' }} height="42" width="42"></img>
                <div style={{ fontSize: 36 + "px", fontWeight: "bold" }}>{isNaN(Number(count)) ? "-": count}</div>
                {/* <div style={{ fontSize: 48 + "px", fontWeight: 100, opacity: 0.25 }}>/</div> */}

            </div>
            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                <p style={{ fontSize: "normal", opacity: 0.75, fontWeight: "bold" }}>{title}</p>
            </div>
        </CardBody>
    </Card>;
}
