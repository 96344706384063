import React, { useState, useEffect } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const PaginationComponent = ({ totalPages, setPageNum, currentPage }) => {
  const startIndexInitial = 0;
  const endIndexInitial = 6;
  const [indices, setIndices] = useState({
    startIndex: startIndexInitial,
    endIndex: endIndexInitial,
  });
  useEffect(() => {
    if (currentPage === indices.endIndex) {
      if (indices.endIndex !== totalPages) {
        setIndices({
          startIndex: indices.endIndex - 2,
          endIndex: Math.min(indices.endIndex + 4, totalPages),
        });
      }
    }
  }, [currentPage]);

  useEffect(() => {
    setIndices({ ...indices, endIndex: Math.min(endIndexInitial, totalPages) });
  }, [totalPages]);

  const onClickFirstPage = () => {
    setPageNum(1);
    setIndices({
      startIndex: startIndexInitial,
      endIndex: Math.min(endIndexInitial, totalPages),
    });
  };
  const renderPageNumbers = () => {
    let pageNumArray = [];
    pageNumArray.push(<PaginationItem />);
    pageNumArray.push(
      <PaginationItem onClick={onClickFirstPage}>
        <PaginationLink className="page">
          <b>First</b>
        </PaginationLink>
      </PaginationItem>
    );
    for (let i = indices.startIndex; i < indices.endIndex; i++) {
      pageNumArray.push(
        <PaginationItem onClick={() => setPageNum(i + 1)}>
          <PaginationLink
            className={currentPage === i + 1 ? "selected-page page" : "page"}
          >
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
      if (i === indices.endIndex - 1) {
        pageNumArray.push(
          <PaginationItem
            onClick={() => {
              totalPages > currentPage && setPageNum(currentPage + 1);
            }}
          >
            <PaginationLink className="page">
              <b> {totalPages > currentPage ? "Next" : "Last"} </b>
            </PaginationLink>
          </PaginationItem>
        );
        pageNumArray.push(<PaginationItem />);
      }
    }
    return pageNumArray;
  };
  return (
    <Pagination aria-label="Services List">{renderPageNumbers()}</Pagination>
  );
};

export default PaginationComponent;
