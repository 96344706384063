import { get } from "apis/api"

export const getSummary = async (successCb, failureCb) => {
    return get("covid/report/quarantine-summary", successCb, failureCb);
}

export const getUserQuarantineData = async (status, { page, pageSize }, successCb, failureCb) => {
    const queryString = new URLSearchParams();

    queryString.set("status", status);
    queryString.set("page", page);
    queryString.set("pageSize", pageSize);

    return get("covid/report/registered-quarantine?" + queryString.toString(), successCb, failureCb);
}