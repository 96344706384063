import React from "react";
import { Container, Row, Col, Table, Input } from "reactstrap";
import { CountWidget } from "./Dasboard/CountWidget";
import { TrackerRow } from "./TrackerRow";
import PaginationComponent from "./PaginationComponent";
import { MapReactElement } from "./MapReactElement";
import { getSummary } from "services/admin-data-service";
import { getUserQuarantineData } from "services/admin-data-service";

const reduceWayPoints = (locationArray) => {
  if (locationArray.length > 23) {
    let temp = [];
    for (let i = 0; i < locationArray.length / 2; i++) {
      temp.push(locationArray[i * 2]);
    }
    return reduceWayPoints(temp);
  } else {
    return locationArray;
  }
};

function getWayPoints(locationArray) {
  locationArray = reduceWayPoints(locationArray);
  let wp = [];
  if (locationArray && locationArray.length) {
    locationArray.forEach((location) => {
      wp.push({
        location: `${location.latitude},${location.longitude}`,
      });
    });
  }
  return wp;
}

const customColMdCountWidget = {
  flex: "0 0 19.99%",
  maxWidth: "19.99%",
  minWidth: "120px",
};

const userSatusMap = {
  Registered: "registered",
  Quarantined: "in_quarantine",
  Active: "active",
  Closed: "closed",
  Breached: "breached",
};

export class Tracker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      init: false,
      rows: [],
      filteredRows: [],
      selectedRow: null,
      selectedCountWidget: "Active",
      mapReactElement: null,
      mapRef: null,
      mapDivRef: null,
      mapDirectionsService: null,
      mapDirectionsRenderer: null,
      summary: {
        totalRegistered: 0,
        totalQuarantine: 0,
        totalActive: 0,
        totalClosed: 0,
        totalBreached: 0,
      },
      pagination: {
        page: 1,
        pageSize: 10,
      },
      searchStr: "",
      sortedColumn: null,
      sortOrder: null,
    };

    this.selectRow = this.selectRow.bind(this);
    this.onUserStatusChange = this.onUserStatusChange.bind(this);
    this.setPageNum = this.setPageNum.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  setPageNum(page) {
    this.setState({
      pagination: { ...this.state.pagination, page: page },
      searchStr: "",
      filteredRows: [],
    });
  }

  onSearchChange(e) {
    const { rows } = this.state;
    const { value } = e.target;

    const filteredRows = value
      ? rows.filter((row) => {
          let match = false;
          for (let key in row) {
            if (
              (String(row[key]) || "")
                .toLowerCase()
                .search(String(value).toLowerCase()) > -1
            ) {
              match = true;
              break;
            }
          }

          return match;
        })
      : [];

    this.setState({ searchStr: value, filteredRows });
  }

  sortRows(rows) {
    const { sortedColumn, sortOrder } = this.state;

    if (sortOrder === null) return rows;

    if (sortedColumn !== null) {
      if (sortOrder === null) return rows;
      else if (sortOrder === "asc") {
        const copy = [...rows];
        copy.sort((a, b) =>
          String(a[sortedColumn] || "").localeCompare(
            String(b[sortedColumn] || "")
          )
        );
        return copy;
      } else if (sortOrder === "desc") {
        const copy = [...rows];
        copy.sort((a, b) =>
          String(b[sortedColumn] || "").localeCompare(
            String(a[sortedColumn] || "")
          )
        );
        return copy;
      }
    }

    return rows;
  }

  onSortChange(fieldName) {
    const { sortedColumn, sortOrder } = this.state;
    let newSortedColumn = fieldName,
      newSortOrder;

    if (newSortedColumn === sortedColumn) {
      if (sortOrder === null) {
        newSortOrder = "asc";
      } else if (sortOrder === "asc") {
        newSortOrder = "desc";
      } else if (sortOrder === "desc") {
        newSortOrder = null;
      }
    } else {
      newSortOrder = "asc";
    }

    this.setState({
      sortedColumn: newSortedColumn,
      sortOrder: newSortOrder,
    });
  }

  renderSortIcons(thisColumn, sortedColumn) {
    return (
      <div className="fa fa-stack">
        <i
          style={{
            color:
              thisColumn === sortedColumn &&
              this.state.sortOrder === "desc" &&
              "#51cbce",
          }}
          className="fa  fa-sort-asc"
          aria-hidden="true"
        ></i>
        <i
          style={{
            color:
              thisColumn === sortedColumn &&
              this.state.sortOrder === "asc" &&
              "#51cbce",
          }}
          className="fa fa-sort-desc"
          aria-hidden="true"
        ></i>
      </div>
    );
  }

  render() {
    return (
      <Container className="pt-4">
        <Row
          style={{
            marginBottom: "1rem",
            marginTop: "1.125rem",
            justifyContent: "center",
          }}
        >
          <Col className="col-md-2 col-sm-6" style={customColMdCountWidget}>
            <CountWidget
              onClick={this.onUserStatusChange}
              count={this.state.summary.totalRegistered}
              title={"Registered"}
              color={"dodgerblue"}
              selected={this.state.selectedCountWidget}
              imageSrc={"./images/regi.png"}
            ></CountWidget>
          </Col>
          <Col className="col-md-2 col-sm-6" style={customColMdCountWidget}>
            <CountWidget
              onClick={this.onUserStatusChange}
              count={this.state.summary.totalQuarantine}
              title={"Quarantined"}
              color={"orange"}
              selected={this.state.selectedCountWidget}
              imageSrc={"./images/quar.png"}
            ></CountWidget>
          </Col>
          <Col className="col-md-2 col-sm-6" style={customColMdCountWidget}>
            <CountWidget
              onClick={this.onUserStatusChange}
              count={this.state.summary.totalActive}
              title={"Active"}
              color={"orangered"}
              selected={this.state.selectedCountWidget}
              imageSrc={"./images/acti.png"}
            ></CountWidget>
          </Col>
          <Col className="col-md-2 col-sm-6" style={customColMdCountWidget}>
            <CountWidget
              onClick={this.onUserStatusChange}
              count={this.state.summary.totalClosed}
              title={"Closed"}
              color={"darkgreen"}
              selected={this.state.selectedCountWidget}
              imageSrc={"./images/clos.png"}
            ></CountWidget>
          </Col>
          <Col className="col-md-2 col-sm-6" style={customColMdCountWidget}>
            <CountWidget
              onClick={this.onUserStatusChange}
              count={this.state.summary.totalBreached}
              title={"Breached"}
              color={"red"}
              selected={this.state.selectedCountWidget}
              imageSrc={"./images/brea.png"}
            ></CountWidget>
          </Col>
        </Row>
        <div>
          {/* <Form>
            <FormGroup> */}
              <Row className="pb-3" style={{display:"flex", justifyContent: "flex-end" }}>
                <Col className="col-md-4 col-sm-12">
                  {this.state.searchStr && (
                    <a
                      href="javascript:void(0)"
                      style={{
                        textDecoration: "none",
                        position: "absolute",
                        margin: "9px 26px",
                        lineHeight: "26px",
                        right: "0px",
                      }}
                      onClick={() =>
                        this.setState({ searchStr: "", filteredRows: [] })
                      }
                    >
                      <i className="nc-icon nc-simple-remove icon-bold"></i>
                    </a>
                  )}
                  <Input
                    type="text"
                    className="form-input"
                    onChange={this.onSearchChange}
                    value={this.state.searchStr}
                    style={{ fontSize: 14 }}
                    placeholder="Search users by name or phone number"
                  ></Input>
                </Col>
              </Row>
            {/* </FormGroup>
          </Form> */}
        </div>
        <div
          style={{
            height: "auto",
            overflowY: "auto",
            overflowX: "auto",
            width: "auto",
          }}
        >
          <Table>
            <thead>
              <tr>
                <th>
                  View Details
                  <span style={{ visibility: "hidden", verticalAlign: 'baseline' }}>
                    {this.renderSortIcons("na", this.state.sortedColumn)}
                  </span>
                </th>
                <th
                  style={{ cursor: "pointer", verticalAlign: 'baseline' }}
                  onClick={this.onSortChange.bind(this, "name")}
                >
                  Name {this.renderSortIcons("name", this.state.sortedColumn)}
                </th>
                <th
                  style={{ cursor: "pointer", verticalAlign: 'baseline' }}
                  onClick={this.onSortChange.bind(this, "phone")}
                >
                  Phone {this.renderSortIcons("phone", this.state.sortedColumn)}
                </th>
                <th
                  style={{ cursor: "pointer", verticalAlign: 'baseline' }}
                  onClick={this.onSortChange.bind(this, "email")}
                >
                  Email {this.renderSortIcons("email", this.state.sortedColumn)}
                </th>
                <th
                  style={{ cursor: "pointer", verticalAlign: 'baseline' }}
                  onClick={this.onSortChange.bind(this, "quarantineStartTime")}
                >
                  Quarantine Start Date{" "}
                  {this.renderSortIcons(
                    "quarantineStartTime",
                    this.state.sortedColumn
                  )}
                </th>
                <th
                  style={{ cursor: "pointer", verticalAlign: 'baseline' }}
                  onClick={this.onSortChange.bind(this, "quarantineEndDate")}
                >
                  Quarantine End Date{" "}
                  {this.renderSortIcons(
                    "quarantineEndDate",
                    this.state.sortedColumn
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {this.sortRows(
                this.state.searchStr ? this.state.filteredRows : this.state.rows
              ).map((eachRow, index) => (
                <TrackerRow
                  key={index}
                  {...eachRow}
                  selectedRow={this.deepEquals(eachRow, this.state.selectedRow)}
                  onSelectRow={this.selectRow}
                  onSelectBreach={this.selectBreach.bind(this)}
                  mapReactElement={
                    this.deepEquals(eachRow, this.state.selectedRow)
                      ? this.state.mapReactElement
                      : null
                  }
                />
              ))}
            </tbody>
          </Table>
        </div>
        {this.state.rows.length != 0 && (
          <Row style={{ padding: ".75rem" }}>
            <Col className="md-col-12">
              <PaginationComponent
                totalPages={this.pageCount}
                currentPage={this.state.pagination.page}
                setPageNum={this.setPageNum}
              />
            </Col>
          </Row>
        )}
      </Container>
    );
  }

  componentDidMount() {
    this.setState({
      mapReactElement: null,
    });

    getSummary(
      (summary) => {
        this.setState({ summary, init: true });
      },
      (err) => {
        console.error("Network error\n", err);
      }
    );

    this.onUserStatusChange(this.state.selectedCountWidget);
  }

  onUserStatusChange(title) {
    this.setState({searchStr: ""});
    this.setState({selectedCountWidget: title})
    getUserQuarantineData(
      userSatusMap[title],
      this.state.pagination,
      ({ quarantineUsers }) => {
        this.setState(
          { rows: quarantineUsers },
          () => {
            if (this.state.init) {
              getSummary(
                (summary) => {
                  this.setState({ summary, init: true });
                },
                (err) => {
                  console.error("Network error\n", err);
                }
              );
            }
          }
        );
      },
      (err) => {
        console.error("Network error\n", err);
      }
    );
  }

  setMap(mapRef, mapDivRef, mapDirectionsService, mapDirectionsRenderer) {
    this.setState(
      {
        mapRef,
        mapDivRef,
        mapDirectionsService,
        mapDirectionsRenderer,
      },
      () => {
      }
    );
  }

  get pageCount() {
    const { selectedCountWidget } = this.state;

    let totalCount = 0;

    switch (selectedCountWidget) {
      case "Active":
        totalCount = this.state.summary.totalActive;
        break;
      case "Quarantined":
        totalCount = this.state.summary.totalQuarantine;
        break;
      case "Registered":
        totalCount = this.state.summary.totalRegistered;
        break;
      case "Closed":
        totalCount = this.state.summary.totalClosed;
        break;
      case "Breached":
        totalCount = this.state.summary.totalBreached;
        break;
    }

    const pgCt = Math.floor(
      (totalCount % this.state.pagination.pageSize === 0
        ? totalCount
        : totalCount + this.state.pagination.pageSize) /
        this.state.pagination.pageSize
    );

    return isNaN(pgCt) ? 1 : pgCt;
  }

  get gridHeight() {
    const rowEpansion = Boolean(this.state.selectedRow) ? 320 : 0,
      headerHeight = 47;
    return this.state.rows.length >= 10
      ? 491 + headerHeight + rowEpansion
      : 49.1 * this.state.rows.length + headerHeight + rowEpansion;
  }

  selectRow(userId) {
    const selectedRow = this.state.rows.find((user) => user.userId === userId);
    this.setState({
      selectedRow: selectedRow,
      mapReactElement: selectedRow ? (
        <MapReactElement
          breachData={null}
          id="qmap"
          config={{
            zoom: 15,
            center: {
              lat: parseFloat(selectedRow.quarantineStartLatitude),
              lng: parseFloat(selectedRow.quarantineStartLongitude),
            },
            fullscreenControl: true,
            fullscreenControlOptions: {
              position: window.google.maps.ControlPosition.LEFT_BOTTOM
            },
          }}
          setMap={this.setMap.bind(this)}
        />
      ) : null,
    });
  }

  deepEquals(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  selectBreach = (data) => {
    if (this.state.mapDirectionsService != null)
      this.state.mapDirectionsRenderer.preserveViewport = false;
    const speed = data.breachData.reduce(
      (acc, cv) => (parseFloat(cv.speed) > acc ? parseFloat(cv.speed) : acc),
      0
    );
    const currentStatus = data.breachData.reduce(
      (acc, cv) =>
        cv.breachType === "ENTER" || acc === "ENTER" ? "ENTER" : "EXIT",
      "EXIT"
    );
    const travelMode = speed > 3.5 ? "DRIVING" : "WALKING";
    const lastLocation = data.breachData[data.breachData.length - 1];
    const destination =
      currentStatus === "ENTER"
        ? `${this.state.selectedRow.quarantineStartLatitude},${this.state.selectedRow.quarantineStartLongitude}`
        : `${lastLocation.latitude},${lastLocation.longitude}`;
    this.state.mapDirectionsService.route(
      {
        origin: {
          query: `${this.state.selectedRow.quarantineStartLatitude},${this.state.selectedRow.quarantineStartLongitude}`,
        },
        destination: {
          query: destination,
        },
        travelMode,
        waypoints: getWayPoints(data.breachData),
      },
      (response, status) => {
        if (status === "OK") {
          this.state.mapDirectionsRenderer.setDirections(response);
        } else {
          /**
           * @todo Show error message to user
           */
          console.error("Directions request failed due to " + status);
        }
      }
    );
  };
}
