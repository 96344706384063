import React from 'react';
import { Router, Route, Switch } from "react-router-dom";
import PrivateRoute from 'routes/PrivateRoute';

import './App.css';
import { createBrowserHistory } from "history";
import { Tracker } from 'components/Tracker';

const LandingPage = React.lazy(() => import('views/LandingPage'));
const UserDetailsPage = React.lazy(() => import('views/UserDetailsPage'));
const LoginPage = React.lazy(() => import('views/LoginPage'));
const MyApprovals = React.lazy(() => import('views/MyApprovals'));
const RegisterPage = React.lazy(() => import('views/RegisterPage'));
const Analytics = React.lazy(() => import("components/Analytics/Analytics"));
const UserManagement = React.lazy(() => import('views/UserManagement'));
const ServicesPage = React.lazy(() => import('views/ServicesPage'));
const Institution = React.lazy(() => import('views/Institution'));
const Events = React.lazy(() => import('views/Events'));
const EventDetails = React.lazy(() => import('views/Events/EventDetails'));
const QRCodeGenerator = React.lazy(() => import('views/QRCodeGenerator'));
const EventsReporting = React.lazy(() => import('views/EventsReporting/index.js'));
const AbuseReport = React.lazy(() => import('views/AbuseReport'));
const OrganiserDashboard = React.lazy(() => import('views/OrganiserDashboard'));

export const history = createBrowserHistory();

const APP_NAME = process.env.REACT_APP_APP_NAME;

function App() {
  return (
    <React.Suspense fallback={<span></span>}>
    <Router history={history}>
      <Switch>
        <Route
          path="/"
          render={props => <LandingPage {...props} />}
          exact
        />
        <Route
          path="/login"
          render={props => <LoginPage {...props} />}
        />
        <Route
          path="/forgot-password"
          render={props => <LoginPage {...props} />}
        />
        <Route
          path="/reset-password"
          render={props => <LoginPage {...props} />}
        />
        <Route
          path="/register"
          render={props => <RegisterPage {...props} />}
        />
        <PrivateRoute path="/dashboard">
          <UserDetailsPage />
        </PrivateRoute>
        {APP_NAME === "service" && <PrivateRoute path="/services" >
          <ServicesPage />
        </PrivateRoute>}
        <PrivateRoute path="/verify-organization">
          <MyApprovals />
        </PrivateRoute>
        {APP_NAME === "admin" && <PrivateRoute path="/tracker">
          <Tracker />
        </PrivateRoute>}

        {APP_NAME === "admin" && <PrivateRoute path="/organisation">
          <Institution />
        </PrivateRoute>}
        {APP_NAME === "admin" && <PrivateRoute exact={true} path="/events">
          <Events />
        </PrivateRoute>}
        {APP_NAME === "admin" && <PrivateRoute exact={true} path="/qrCodeGenerator">
          <QRCodeGenerator />
        </PrivateRoute>}
        {APP_NAME === "admin" && <PrivateRoute path="/events/detail">
          <EventDetails />
        </PrivateRoute>}
        <PrivateRoute isAdminRoute={true} path="/userManagement" >
          <UserManagement />
        </PrivateRoute>
        <PrivateRoute path="/eventsReporting">
          <EventsReporting />
        </PrivateRoute>
        <PrivateRoute path="/analytics" >
            <Analytics />
        </PrivateRoute>
        <PrivateRoute  path="/abusereport" >
          <AbuseReport />
        </PrivateRoute>
        <PrivateRoute  path="/organiserdashboard" >
          <OrganiserDashboard />
        </PrivateRoute>
      </Switch>
    </Router>
    </React.Suspense>
  );
}

export default App;
