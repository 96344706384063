export default {
 PENDING_EMAIL_MSG : `Please click on the link that has been sent to your email account to verify your email.`,
 PENDING_EMAIL_HEADING : "Pending Email Verification",
 PENDING_EMAIL_HEADING_SIGNUP:'Thank you for Registering!',
 PENDING_EMAIL_LOGIN_HEADING: "Email Verification",
 PENDING_EMAIL_LOGIN_SUBHEADING: "Login to verify email",
 DESCRIPTION_LANDING : `Organisations such as religious places, educational institutes, shopping malls, cafe/restaurants, hotel/guest houses, supermarkets etc.
 can use GH COVID-19 Tracker Event Management platform to record attendance for gatherings during COVID-19 pandemic. It should support the Government's COVID-19 Track and Trace efforts to identify the impacted citizens and provide the necessary guidance following Government's quarantine guidelines.`,
 services : [
     {
         icon : "fa fa-map-marker",
         imageIcon: "./images/tollfree.png",
         seeMore: false,
         title: "Onboard Organisation",
         description: [
            "Onboard your Organisation to manage and record attendance of visitors.",
            "You will get a unique QR code for your organisation that you can display at the entrance of the venue.",
            "Users can mark their attendance by scanning the QR code or using USSD at the entrance of the venue."
         ]
     },
     {
        icon : "fa fa-calendar",
        imageIcon: "./images/conference.png",
        seeMore: false,
        title: "Organise Events",
        description: [
            "Create & manage events and record attendance of your guests.",
            "You will get a unique QR code for your event that you can display at the entrance of the event.",
            "Users can register for the events through the App or USSD and mark their attendance by scanning the QR code or using the USSD service at the entrance."
        ]
    },
    // {
    //     icon : "nc-icon nc-mobile",
    //     imageIcon: "./images/fmc.png",
    //     seeMore: false,
    //     title: "Open & Closed Events",
    //     description: [
    //         "Users can search and register for Open events using GH COVID-19 Tracker app.",
    //         " For closed events, you may consider using your existing channels to share the event passcode with your invitees.",
    //         "Users can search for the event based on the passcode and register for the event."
    //     ]
    // },
    {
        icon : "fa fa-bar-chart",
        imageIcon: "./images/bolt.png",
        seeMore: false,
        title: "Reports",
        description: [
            "Dashboard specifying the total capacity of the venue or an event, event registrations and number of attendees."
        ]
    }
 ],
 colors: {
    success: "rgb(107, 208, 152)"
 },
 jsxHtmlAltCode: {
    nbsp: '\u00A0'
 }
}