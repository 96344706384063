import utils from "utils";

export default {
  login: [

  ].filter(nav => !nav.hasOwnProperty("show") || nav.show),
  landing: [
    {
      path: "/login",
      label: "Login",
      show: true
    },
    {
      path: "/register",
      label: "Register",
      show: true
    }
  ].filter(nav => !nav.hasOwnProperty("show") || nav.show),
  userDashboard: [
    {
      path: "/dashboard",
      label: "My Details",
      show: true
    },
    {
      path: "/services",
      label: "Services",
      show: process.env.REACT_APP_APP_NAME === "service"
    },
    {
      path: "/organisation",
      label: "Organisation",
      show: process.env.REACT_APP_APP_NAME === "admin"
    },
    {
      path: "/events",
      label: "Events",
      show: process.env.REACT_APP_APP_NAME === "admin"
    },
    {
      path: "/organiserdashboard",
      label: "Dashboard",
      show: process.env.REACT_APP_APP_NAME === "admin"
    },
    {
      path: "/login",
      label: "Logout",
      show: true,
      function: () => {
        utils.clearStorage();
      }
    }
  ].filter(nav => !nav.hasOwnProperty("show") || nav.show),
  GHSAdminDashboard: [
    {
      path: "/dashboard",
      label: "My Details",
      show: true
    },
    {
      path: "/tracker",
      label: "Quarantine Cases",
      show: process.env.REACT_APP_APP_NAME === "admin"
    },
    {
      path: "/eventsReporting",
      label: "Event Attendance",
      show: process.env.REACT_APP_APP_NAME === "admin"
    },
    // {
    //   path: "/userManagement",
    //   label: "User Management",
    //   show: true
    // },
    // {
    //   path: "/verify-organization",
    //   label: "Verify Organization",
    //   show: true
    // },
    {
      path: "/login",
      label: "Logout",
      show: true,
      function: () => {
        utils.clearStorage();
      }
    }
  ].filter(nav => !nav.hasOwnProperty("show") || nav.show),
  operationsUserDashboard: [
    {
      path: "/dashboard",
      label: "My Details",
      show: true
    },
    {
      path: "/verify-organization",
      label: "Verify Organization",
      show: true
    },
    {
      path: "/abusereport",
      label: "Abuse Report",
      show: true,
    },
    // {
    //   path: "/analytics",
    //   label: "Analytics",
    //   show: process.env.REACT_APP_APP_NAME === "admin"
    // },
    {
      path: "/login",
      label: "Logout",
      show: true,
      function: () => {
        utils.clearStorage();
      }
    }
  ].filter(nav => !nav.hasOwnProperty("show") || nav.show),
  adminUserDashboard: [
    {
      path: "/dashboard",
      label: "My Details",
      show: true,
    },
    {
      path: "/userManagement",
      label: "User Management",
      show: true,
    },
    {
      path: "/verify-organization",
      label: "Verify Organization",
      show: true,
    },
    {
      path: "/abusereport",
      label: "Abuse Report",
      show: true,
    },
    {
      path: "/services",
      label: "Services",
      show: process.env.REACT_APP_APP_NAME === "service"
    },
    {
      path: "/tracker",
      label: "Quarantine Cases",
      show: process.env.REACT_APP_APP_NAME === "admin"
    },
    {
      path: "/eventsReporting",
      label: "Event Attendance",
      show: process.env.REACT_APP_APP_NAME === "admin"
    },
    {
      path: "/analytics",
      label: "Analytics",
      show: process.env.REACT_APP_APP_NAME === "admin"
    },
    {
      path: "/login",
      label: "Logout",
      show: true,
      function: () => {
        utils.clearStorage();
      }
    }
  ].filter(nav => !nav.hasOwnProperty("show") || nav.show),
}