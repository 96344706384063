import orderBy from "lodash.orderby";
import navData from "routes/navData";
var groupBy = require("lodash.groupby");

class utils {
  static setAuthInfo(authInfo) {
    const existingInfo = JSON.parse(sessionStorage.getItem("auth"));
    if (!authInfo.accessToken) {
      const updatedInfo = {...authInfo, accessToken: existingInfo.accessToken};
      sessionStorage.setItem("auth", JSON.stringify(updatedInfo));
    } else {
      const authTokenString = JSON.stringify(authInfo);
      sessionStorage.setItem("auth", authTokenString);
    }
    this.getAuthToken();
  }

  static setDeptCode(deptCode) {
    sessionStorage.setItem("deptCode", deptCode);
  }

  static getDeptCode() {
    const deptCode = sessionStorage.getItem("deptCode");
    return deptCode;
  }

  static validateEmail(emailString) {
    const regexEmail = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    );
    return regexEmail.test(String(emailString).toLowerCase());
  }

  static getAuthToken() {
    const authInfoString = sessionStorage.getItem("auth");
    const authInfo = JSON.parse(authInfoString);
    if (authInfo && authInfo.accessToken) {
      return authInfo.accessToken;
    }
    return "";
  }

  static isValidated() {
    const authInfoString = sessionStorage.getItem("auth");
    const authInfo = JSON.parse(authInfoString);
    return authInfo.validationStatus.toLowerCase() === 'approved';
  }

  static validationStatus() {
    const authInfoString = sessionStorage.getItem("auth");
    const authInfo = JSON.parse(authInfoString);
    return authInfo.validationStatus;
  }

  static clearStorage() {
    sessionStorage.removeItem('auth');
    sessionStorage.removeItem('user');
  }

  static setAuthToken(token) {
    const authInfoString = sessionStorage.getItem("auth");
    const authInfo = JSON.parse(authInfoString);
    if (token && authInfo) {
      authInfo.accessToken = token;
    }
    const authTokenString = JSON.stringify(authInfo);
    sessionStorage.setItem("auth", authTokenString);
  }

  static getAuthFunction() {
    const authInfoString = sessionStorage.getItem("auth");
    const authInfo = JSON.parse(authInfoString);
    if (authInfo.function) {
      return authInfo.function;
    }
    return "";
  }

  static isAuthorized() {
    const authInfoString = sessionStorage.getItem("auth");
    const authInfo = JSON.parse(authInfoString);
    return !!authInfo;
  }

  static isAdmin() {
    const authInfoString = sessionStorage.getItem("auth");
    const authInfo = JSON.parse(authInfoString);
    let isAdmin;
    if (authInfo && authInfo.userRole) {
      isAdmin = authInfo.userRole.find((role) => role === "ADMIN_ROLE");
    }
    return !!isAdmin;
  }

  static isGHSAdmin() {
    const authInfoString = sessionStorage.getItem("auth");
    const authInfo = JSON.parse(authInfoString);
    let isGHSAdmin;
    if (authInfo && authInfo.userRole) {
      isGHSAdmin = authInfo.userRole.find((role) => role === "GHS_ADMIN_ROLE");
    }
    return !!isGHSAdmin;
  }


  static isOperationsUser() {
    const authInfoString = sessionStorage.getItem("auth");
    const authInfo = JSON.parse(authInfoString);
    let isOperationsUser;
    if (authInfo && authInfo.userRole) {
      isOperationsUser = authInfo.userRole.find((role) => role === "OPERATIONS_ROLE");
    }
    return !!isOperationsUser;
  }

  static userRole() {
    const authInfoString = sessionStorage.getItem("auth");
    const authInfo = JSON.parse(authInfoString);
    return authInfo.userRole;
  }

  static getNavData() {
    const authInfoString = sessionStorage.getItem("auth");
    const authInfo = JSON.parse(authInfoString);
    // let isUser ;
    let isAdmin, isGHSAdmin, isOperationManagerUser;
    let navDataForRoles = [];
    if (authInfo.userRole) {
      isAdmin = authInfo.userRole.find((role) => role === "ADMIN_ROLE");
      // isUser = authInfo.userRole.find((role) => role === "USER_ROLE");
      isGHSAdmin = authInfo.userRole.find((role) => role === "GHS_ADMIN_ROLE");
      isOperationManagerUser = authInfo.userRole.find((role) => role === "OPERATIONS_ROLE");
      if (isAdmin) {
        navDataForRoles = navData.adminUserDashboard;
      } else if (isGHSAdmin) {
        navDataForRoles = navData.GHSAdminDashboard;
      } else if (isOperationManagerUser) {
        navDataForRoles = navData.operationsUserDashboard;
      } else {
        navDataForRoles  = navData.userDashboard;
      }
    }
    return navDataForRoles;
  }
  static isEmailVerified() {
    const authInfoString = sessionStorage.getItem("auth");
    const authInfo = JSON.parse(authInfoString);
    return authInfo.emailVerified;
  }

  static cloneObj(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static getStringFromISODate(isoString) {
    if (isoString) {
      let date = new Date(isoString);
      return date.toLocaleString();
    }
    return "";
  }

  static getOrderedCollection(collection = [], iteratees, order) {
    //asc desc
    if (iteratees[0] === "srNumber") {
      return orderBy(
        collection,
        [
          function (o) {
            return Number(o.srNumber.split("-")[1]);
          },
        ],
        order
      );
    }
    return orderBy(collection, iteratees, order);
  }
  static isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  static isEqualArray(array1, array2) {
    let is_same =
      array1.length == array2.length &&
      array1.every(function (element, index) {
        return element === array2[index];
      });
    return is_same;
  }

  static processCamelCase(string) {
    return string.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  }

  static getDropdownStyles() {
    return {
      control: (provided, state) => ({
        ...provided,
        fontSize: 16,
        borderStyle: "none",
        boxShadow: state.isFocused
          ? "0 0 0 2px #51cbce"
          : state.selectProps.invalid
          ? "0 0 0 1px #dc3545"
          : "0 0 0 1px #dddddd",
      }),
      placeholder: (provided, state) => ({
        fontSize: 14,
        color: '#7f7e80'
      }),
      option: (provided, { isSelected, isFocused, isDisabled }) => ({
        ...provided,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "rgba(81, 203, 206,0.9)"
          : isFocused
          ? "rgba(81, 203, 206,0.1)"
          : null,
        ":active": {
          backgroundColor: "rgba(81, 203, 206,1)",
          color: "white",
        },
      }),
    };
  }
  static setUserInfo(userInfo) {
    const userString = JSON.stringify(userInfo);
    const encryptedString = btoa(userString);
    sessionStorage.setItem("user", encryptedString);
  }

  static getUserInfo() {
    const encryptedString = sessionStorage.getItem("user");
    if (encryptedString) {
      const userString = atob(encryptedString);
      return JSON.parse(userString);
    }
    return {};
  }

  static groupArrayBy(array, key) {
    return groupBy(array, key);
  }

  static validatePassword(password) {
    var minMaxLength = /^[\s\S]{8,32}$/,
      upper = /[A-Z]/,
      lower = /[a-z]/,
      number = /[0-9]/,
      special = /[^A-Za-z0-9]/,
      count = 0;

    if (minMaxLength.test(password)) {
      // Only need 3 out of 4 of these to match
      if (upper.test(password)) count++;
      if (lower.test(password)) count++;
      if (number.test(password)) count++;
      if (special.test(password)) count++;
    }

    return count >= 3;
  }

  static validateField(data) {
    debugger;
    let error = false;
    let errorMessage = "";
    if (
      data.validation.required &&
      (data.value === "" || data.value === undefined)
    ) {
      error = true;
      errorMessage = `${data.label} cannot be empty`;
      return { error, errorMessage };
    }
    if (
      data.validation.minlength &&
      data.value.length < data.validation.minlength
    ) {
      error = true;
      errorMessage = `${data.label} should be more than ${data.validation.minlength}`;
      return { error, errorMessage };
    }
    if (
      data.validation.maxlength &&
      data.value.length > data.validation.maxlength
    ) {
      error = true;
      errorMessage = `${data.label} should be less than ${data.validation.maxlength}`;
      return { error, errorMessage };
    }
    if (data.validation.regex) {
      const regex = new RegExp(data.validation.regex);
      if (!regex.test(data.value.toLowerCase().trim())) {
        error = true;
        errorMessage = `${data.validation.regexMessage} in ${data.label}`;
        return { error, errorMessage };
      }
    }
    return { error, errorMessage };
  }

  static onValidate(e, field, index, isFocused, formArray) {
    formArray.forEach((item) => {
      if (item.key === field.key) {
        if (isFocused) {
          item.invalid = false;
        } else if (
          field.validation.required &&
          (field.value === "" ||
            field.value === undefined ||
            field.value === null)
        ) {
          item.invalid = true;
        }
      }
    });
    return formArray;
  }
}

export default utils;
