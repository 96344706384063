import React, {useState, useLayoutEffect, useRef} from 'react';

export function MapReactElement({ id, config, setMap }) {
    const [mapRef, setMapRef] = useState(null);
    // const [directionsService, setDirectionsService] = useState(null);
    // const [directionsRenderer, setDirectionsRenderer] = useState(null);
    const mapDivRef = useRef();

    useLayoutEffect(() => {
      if (mapRef === null) {
        var directionsService = new window.google.maps.DirectionsService();
        var directionsRenderer = new window.google.maps.DirectionsRenderer({
          suppressMarkers: true,
          preserveViewport: true,
          polylineOptions: {
            strokeColor: "rgba(222, 70, 85)",
            strokeOpacity: 0.4,
            strokeWeight: 4,
          },
        });
        const googleMap = new window.google.maps.Map(
          document.getElementById(id),
          config
        );

        new window.google.maps.Circle({
          strokeColor: "rgba(104, 170, 105)",
          strokeOpacity: 0.5,
          strokeWeight: 1,
          fillColor: "rgba(104, 170, 105)",
          fillOpacity: 0.4,
          map: googleMap,
          center: config.center,
          radius: 200,
        });

        directionsRenderer.setMap(googleMap);

        directionsService.route(
          {
            origin: { query: `${config.center.lat},${config.center.lng}` },
            destination: { query: `${config.center.lat},${config.center.lng}` },
            travelMode: "WALKING",
            waypoints: [],
          },
          function (response, status) {
            if (status === "OK") {
              directionsRenderer.setDirections(response);

            } else {
              console.error("Directions request failed due to " + status);
            }
          }
        );
        setMapRef(googleMap);
        setMap(googleMap, mapDivRef, directionsService, directionsRenderer);
        // setDirectionsService(directionsService);
        // setDirectionsRenderer(directionsRenderer);
      }
    });

    return <div ref={mapDivRef} id={id} style={{ height: "100%" }}></div>;
  }
