import React from "react";
import {
    Route,
    Redirect,
} from "react-router-dom";
import DashBoardPage from "views/DashboardPage";
import utils from "utils";

function PrivateRoute({ children, isAdminRoute, ...rest }) {
    let isAuthorized = utils.isAuthorized();
    if (isAdminRoute) {
        isAuthorized = utils.isAdmin();
    }
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthorized ? (
                    <DashBoardPage>
                       {React.cloneElement(children, props)}
                    </DashBoardPage>
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
            }
        />
    )
}

export default PrivateRoute