/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

import "./CommonNavbar.css";

const cssNav = {
  marginLeft: 15,
  marginRight: 15,
};

const cssNavItem = {
  paddingRight: 0,
  paddingLeft: 0,
};

const cssActiveNavItem = {
  color: "#DD562B",
  // borderBottom: "3px solid #66615B"
};

const navHeight = 50;

function CommonNavbar({ shouldShowWhiteHeader, navItems = [] }) {
  const [navbarColor, setNavbarColor] = React.useState(
    shouldShowWhiteHeader ? "" : "navbar-transparent"
  );
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [activeNavItem, setActiveNavItem] = React.useState(null);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    if (!shouldShowWhiteHeader) {
      const updateNavbarColor = () => {
        if (
          document.documentElement.scrollTop > navHeight - 1 ||
          document.body.scrollTop > navHeight - 1
        ) {
          setNavbarColor("");
        } else if (
          document.documentElement.scrollTop < navHeight ||
          document.body.scrollTop < navHeight
        ) {
          setNavbarColor("navbar-transparent");
        }
      };

      window.addEventListener("scroll", updateNavbarColor);

      return function cleanup() {
        window.removeEventListener("scroll", updateNavbarColor);
      };
    }

    if (!activeNavItem) setActiveNavItem(window.location.pathname);
  });
  const onClickNav = (item) => {
    setActiveNavItem(item.path);
    if (item && item.function) item.function();
  };

  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container className="px-0">
        <div className="navbar-translate">
          <NavbarBrand
            className="py-0 my-0"
            data-placement="bottom"
            to="/"
            target="_blank"
            title="Ministry Of Communications"
            tag={Link}
          >
            <img
              src={require("assets/img/logo-moc.png")}
              alt="Ministry Of Communications"
              style={{ height: 70 }}
            />
          </NavbarBrand>
          {navItems.length > 0 && (
            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          )}
        </div>
        {navItems.length > 0 && (
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={navbarCollapse}
          >
            <Nav navbar>
              {navItems.map((eachItem, index) => (
                <NavItem style={cssNav} key={index}>
                  <NavLink
                    style={
                      eachItem.path === activeNavItem
                        ? { ...cssNavItem, ...cssActiveNavItem }
                        : cssNavItem
                    }
                    className={eachItem.path === activeNavItem ? "active" : ""}
                    onClick={() => onClickNav(eachItem)}
                    to={eachItem.path}
                    tag={Link}
                  >
                    {eachItem.label}
                  </NavLink>
                </NavItem>
              ))}
              {/* <div style={{display:'flex', flexDirection:'column', justifyContent: 'center'}}>
                <a href="tel:0307007001" style={{
                  color: '#f5593d',
                  fontSize: '16px',
                  fontWeight: 'bold'
                }}>
                  <i className="fa fa-phone"></i>
              0307007001
            </a>
              </div> */}
              <li class="nav-item" style={{marginLeft: "15px", marginRight: "15px" }}>
                <a class="nav-link" href="tel:0307007001" style={{paddingRight: "0px", paddingLeft: "0px", color: '#f5593d',
                  fontSize: '14px',
                  fontWeight: 'bold'}}><i className="fa fa-phone"></i>
              0307007001</a></li>
            </Nav>
          </Collapse>

        )}

      </Container>
    </Navbar>
  );
}

export default CommonNavbar;
