import axios from 'axios';
import utils from '../utils';
import { history } from 'App';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const baseUrlEvents = baseUrl;

//sign in api 
export const loginUser = async (authToken, aWQ, successcb, failurecb) => {
    try {
        const queryParams = new URLSearchParams("");

        if (aWQ) {
            queryParams.append("aWQ", aWQ);
        }

        const response = await axios.get(baseUrl + 'auth/sign-in' + (aWQ ? "?" : "") + queryParams.toString(), { headers: { 'Authorization': `Basic ${authToken}` } });
        const { message } = response.data;
        if (response.status == 200) {
            if (response.data.return) {
                utils.setAuthInfo(response.data.return);
                successcb();
            }
            else
                failurecb(message);
        }
        else
            throw { message: "Login failed with error code " + response.data.status }
    }
    catch (err) {
        failurecb(err.message)
    }
}

export const resetPassword = async (path, authorization, onSuccess, onFailure) => {
    try {
        const response = await axios.get(baseUrl + path, { headers: { 'Authorization': `Basic ${authorization}` } });

        if (response.status == 200) {
            onSuccess(response);
        } else {
            console.error(response);
        }
    }
    catch (err) {
        onFailure(err.message);
    }
}

//sign up api 
export const signUpUser = async (payload, successcb, failurecb) => {
    try {
        const response = await axios.put(baseUrl + 'auth/sign-up', payload);
        if (response.status == 200) {
            const { errorObject, message } = response.data;
            if (errorObject)
                failurecb(errorObject[0])
            else
                successcb(message);
        }
        else {
            throw { message: "Registration failed with error code " + response.data.status }
        }
    }
    catch (err) {
        failurecb(err.message)
    }
}

//private routes apis

const authInstance = axios.create({
    baseURL: baseUrl
});

authInstance.interceptors.request.use(function (config) {
    config.headers.Authorization = 'Bearer ' + utils.getAuthToken();
    return config;
});

export const get = async (path, callbackSuccess, callbackErr) => {
    try {
        const response = await authInstance.get(path);
        
        if (response.data.refreshToken) {
            utils.setAuthToken(response.data.refreshToken)
        }
        if (response.status == 200 && response.data.return) {
            if (path === '/user/detail' && response.data.return) {
                utils.setAuthInfo(response.data.return);
            }
            callbackSuccess(response.data.return);
        }
        else if (response.data.errorObject) {
            const {errorObject} = response.data
            if (typeof errorObject === 'string')
                throw { message: errorObject }
            else
                throw { message:errorObject[0] }
        }
        else
            throw { message: response.data.message }

    }
    catch (err) {
        catchErr(err, callbackErr);
    }
}

export const put = async (path, payload, callbackSuccess, callbackErr) => {
    try {
        const response = await authInstance.put(path, payload);
        
        if (response.data.refreshToken) {
            utils.setAuthToken(response.data.refreshToken)
        }
        if (response.status == 200 && response.data.return) {
            callbackSuccess(response.data.return);
        }
        else if (response.data.errorObject) {
            const {errorObject} = response.data
            if (typeof errorObject === 'string')
                throw { message: errorObject }
            else
                throw { message:errorObject[0] }
        }
        else throw { message: response.data.message }

    }
    catch (err) {
        catchErr(err, callbackErr);
    }
}

export const patch = async (path, payload, callbackSuccess, callbackErr) => {
    try {
        const response = await authInstance.patch(path, payload);
        
        if (response.data.refreshToken) {
            utils.setAuthToken(response.data.refreshToken)
        }
        if (response.status == 200 && response.data.return) {
            callbackSuccess(response.data.return);
        }
        else if (response.data.errorObject) {
            const {errorObject} = response.data
            if (typeof errorObject === 'string')
                throw { message: errorObject }
            else
                throw { message:errorObject[0] }
        }
        else throw { message: response.data.message }

    }
    catch (err) {
        catchErr(err, callbackErr);
    }
}

export const post = async (path, payload, callbackSuccess, callbackErr) => {
    try {
        const response = await authInstance.post(path, payload);
        
        if (response.data.refreshToken) {
            utils.setAuthToken(response.data.refreshToken)
        }
        if (response.status == 200 && response.data.return) {
            callbackSuccess(response.data.return);
        }
        else if (response.data.errorObject) {
            const {errorObject} = response.data
            if (typeof errorObject === 'string')
                throw { message: errorObject }
            else
                throw { message:errorObject[0] }
        }
        else
            throw { message: response.data.message }

    }
    catch (err) {
        catchErr(err, callbackErr);
    }
}

export const deleteApi = async (path, payload, callbackSuccess, callbackErr) => {
    try {
        const response = await authInstance.delete(path, {data: payload});
        
        if (response.data.refreshToken) {
            utils.setAuthToken(response.data.refreshToken)
        }
        if (response.status == 200 && response.data.return) {
            callbackSuccess(response.data.return);
        }
        else if (response.data.errorObject) {
            const {errorObject} = response.data
            if (typeof errorObject === 'string')
                throw { message: errorObject }
            else
                throw { message:errorObject[0] }
        }
        else
            throw { message: response.data.message }

    }
    catch (err) {
        catchErr(err, callbackErr);
    }
}

export const postWithAdditionHeadersParam = async (path, payload, config, callbackSuccess, callbackErr) => {
    try {
        const response = await authInstance.post(path, payload,config);
        
        if (response.data.refreshToken) {
            utils.setAuthToken(response.data.refreshToken)
        }
        if (response.status == 200 && response.data.return) {
            callbackSuccess(response.data.return);
        }
        else if (response.data.errorObject) {
            const {errorObject} = response.data
            if (typeof errorObject === 'string')
                throw { message: errorObject }
            else
                throw { message:errorObject[0] }
        }
        else
            throw { message: response.data.message }

    }
    catch (err) {
        catchErr(err, callbackErr);
    }
}

function catchErr(err, callbackErr) {
    if (err.response && err.response.data.status == 401) {
        history.push('/login');
        utils.setAuthInfo('');
    }
    else
        callbackErr(err.message)
}

//event management

const authInstanceWithBaseUrl = axios.create({
    baseURL: baseUrlEvents
});

authInstanceWithBaseUrl.interceptors.request.use(function (config) {
    config.headers.Authorization = 'Bearer ' + utils.getAuthToken();
    return config;
});

export const getWithEventUrl = async (path, callbackSuccess, callbackErr) => {
    try {
        const response = await authInstanceWithBaseUrl.get(path);
        
        if (response.data.refreshToken) {
            utils.setAuthToken(response.data.refreshToken)
        }
        if (response.status == 200 && response.data.return) {
            callbackSuccess(response.data.return);
        }
        else if (response.data.errorObject) {
            const {errorObject} = response.data
            if (typeof errorObject === 'string')
                throw { message: errorObject }
            else
                throw { message:errorObject[0] }
        }
        else
            throw { message: response.data.message }

    }
    catch (err) {
        catchErr(err, callbackErr);
    }
}

export const putWithEventUrl = async (path, payload, callbackSuccess, callbackErr) => {
    try {
        const response = await authInstanceWithBaseUrl.put(path, payload);
        if (response.data.refreshToken) {
            utils.setAuthToken(response.data.refreshToken)
        }
        if (response.status == 200 && response.data.return) {
            callbackSuccess(response.data.return);
        }
        else if (response.data.errorObject) {
            const {errorObject} = response.data
            if (typeof errorObject === 'string')
                throw { message: errorObject }
            else
                throw { message:errorObject[0] }
        }
        else throw { message: response.data.message }

    }
    catch (err) {
        catchErr(err, callbackErr);
    }
}
export const deleteWithEventUrl = async (path, payload, callbackSuccess, callbackErr) => {
    try {
        const response = await authInstanceWithBaseUrl.delete(path, {data: payload});
        if (response.data.refreshToken) {
            utils.setAuthToken(response.data.refreshToken)
        }
        if (response.status == 200 && response.data.return) {
            callbackSuccess(response.data.return);
        }
        else if (response.data.errorObject) {
            const {errorObject} = response.data
            if (typeof errorObject === 'string')
                throw { message: errorObject }
            else
                throw { message:errorObject[0] }
        }
        else throw { message: response.data.message }

    }
    catch (err) {
        catchErr(err, callbackErr);
    }
}


export const postWithEventUrl = async (path, payload, callbackSuccess, callbackErr) => {
    try {
        const response = await authInstanceWithBaseUrl.post(path, payload);
        if (response.data.refreshToken) {
            utils.setAuthToken(response.data.refreshToken)
        }
        if (response.status == 200 && response.data.return) {
            callbackSuccess(response.data.return);
        }
        else if (response.data.errorObject) {
            const {errorObject} = response.data
            if (typeof errorObject === 'string')
                throw { message: errorObject }
            else
                throw { message:errorObject[0] }
        }
        else
            throw { message: response.data.message }

    }
    catch (err) {
        catchErr(err, callbackErr);
    }
}
